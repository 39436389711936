import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

export const StyledLink = styled(NavLink)`
  color: #fff;

  &.active {
    color: #555;
  }
`;
